import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { IconContext } from "react-icons";

import Header from "./header";
import Footer from "./footer";

function Layout({ children }) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              copyright
            }
          }
        }
      `}
      render={data => (
        <div className="flex flex-col font-sans md:min-h-screen text-gray-800 w-full">
          <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
          <Header siteTitle={data.site.siteMetadata.title} />

          <div className="flex flex-col flex-1 md:justify-center w-full bg-brand">
            {children}
          </div>

          <Footer siteCopyright={data.site.siteMetadata.copyright} />
          </IconContext.Provider>
        </div>
      )}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
