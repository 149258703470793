import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

function Footer({ siteCopyright }) {
  return (
    <footer className="bg-accent z-40">
            <nav className="flex flex-wrap items-center justify-between max-w-5xl mx-auto p-4">

          <div className="text-sm mx-auto">
            <Link
              to="/"
              aria-label="Go back to the home page"
              className="block uppercase border-b-4 border-transparent hover:border-tertiary mt-4 md:inline-block md:mt-0 mr-6 text-gray-200"
            >
              Home
            </Link>

            <Link
              to="/#team"
              aria-label="Read the short team member bios"
              className="block md:inline-block uppercase border-b-4 border-transparent hover:border-tertiary mt-4 md:mt-0 mr-6 text-gray-200"
            >
              Team
            </Link>

            <Link
              to="/projects"
              aria-label="Read about the featured projects"
              className="block md:inline-block uppercase border-b-4 border-transparent hover:border-tertiary mt-4 md:mt-0 mr-6 text-gray-200"
            >
              Projects
            </Link>
            { }
            <Link
              to="/contact"
              aria-label="Read the company contact details"
              className="block md:inline-block uppercase border-b-4 border-transparent hover:border-tertiary mt-4 md:mt-0 text-gray-200"
            >
              Contact
            </Link>
          </div>
      </nav>
      <div className="flex justify-between max-w-5xl mx-auto p-4 text-sm text-gray-400">
        <p className="font-hairline text-gray-200">
          Credit: <a href="https://nigelball.tech">NigelBall.tech</a>
        </p>

        <p className="font-display">{siteCopyright}</p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  siteTitle: PropTypes.string
};

Footer.defaultProps = {
  siteCopyright: ``
};

export default Footer;
