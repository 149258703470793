import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";

function Header({ siteTitle }) {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <header className="bg-accent z-40">
      <nav className="flex flex-wrap items-center justify-between max-w-5xl mx-auto p-4">
        <Link
        to="/" className="flex items-center text-gray-200"
        aria-label="Return to the site homepage">
          <span className="font-display font-300 text-xl tracking-tight">
            {siteTitle}
          </span>
        </Link>

        <button
          className="block md:hidden border border-gray-200 flex items-center px-3 py-2 rounded text-gray-200"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <div
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:flex md:items-center w-full md:w-auto`}
        >
          <div className="text-sm">
            <Link
              to="/"
              aria-label="Return to the site homepage"
              className="block uppercase border-b-4 border-transparent hover:border-tertiary mt-4 md:inline-block md:mt-0 mr-6 text-gray-200"
            >
              Home
            </Link>

            <Link
              to="/#team"
              aria-label="Read the short team member bios"
              className="block uppercase border-b-4 border-transparent hover:border-tertiary md:inline-block mt-4 md:mt-0 mr-6 text-gray-200"
            >
              Team
            </Link>

            <Link
              to="/projects"
              aria-label="Read about the featured projects"
              className="block uppercase border-b-4 border-transparent hover:border-tertiary md:inline-block mt-4 md:mt-0 mr-6 text-gray-200"
            >
              Projects
            </Link>
            { }
            <Link
              to="/contact"
              aria-label="Read the company contact details"
              className="block uppercase border-b-4 border-transparent hover:border-tertiary focus:border-tertiary md:inline-block mt-4 md:mt-0 text-gray-200"
            >
              Contact
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
